
.wholeSale-container {
    /* justify-content: center; */
}

.wholeSale-wrapper {
    /* height: 100vh; */
    align-items: center;
    justify-content: center;
}

.wholeSale-desc {
    display: flex;
    justify-content: center;
    align-items: center;
}

.wholeSale-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wholeSale-img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.wholeSale-img img {
    max-width: 80%;
}

.wholeSale-welcome {
    font-size: 2rem;
    font-weight: 600;
}

.wholeSale-form {
    justify-content: center;
}

@media screen and (max-width: 992px) {
    .wholeSale-container {
        margin-top: 13vh;
    }
}

@media screen and (max-width: 768px) {
    .wholeSale-wrapper {
        height: initial;
    }

    /* .wholeSale-img img {
        max-width: 350px;
    } */
}

@media screen and (max-width: 576px) {
    .wholeSale-container {
        margin-top: 88px;
    }
    .page-title h1{
        font-size: 3rem;
    }

    /* .wholeSale-img img {
        max-width: 150px;
    } */
}

