.musicScreen-container {
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
    min-height: 100vh;
}

.music-player-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.apple-playlist {
    
}

@media screen and (max-width: 992px) {
    .musicScreen-container {
        margin-top: 13vh;
    }
}

@media screen and (max-width: 576px){
    .musicScreen-container {
        margin-top: 88px;
    }
}