
.refund-container {
    margin-top: 2vh;
}

.refund-content-wrapper {
    margin-top: 2vh;
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
}

@media screen and (max-width: 992px) {
    .refund-container {
        margin-top: 13vh;
    }
}

@media screen and (max-width: 576px) {
    .refund-container {
        margin-top: 88px;
    }
}

