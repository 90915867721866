

footer {
    background-color: #e6e2de;
    height: auto;
}

.footer-container {
    /* margin-top: 12vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly ;
    max-width: 100%;
    height: 45vh;
    /* border: solid #303030; */
}

.frame-logo-container {
    display: flex;
    justify-content: center;
}

.frame-logo img {
    margin-left: 10px;
    width: 200px;
}

.policy-container {
    display: flex;
    justify-content:space-between;
    padding-left: 1vw;
    padding-right: 1vw ;
}

.policy-container .col {
    display: flex;
    justify-content: center;
}

.policy-container .nav-link {
    padding: 5px;
    width: fit-content;
    border-bottom: 2.5px solid #303030;
    font-weight: 500;
    background: transparent;
    transition: color 0.3s ease-in-out;
    transition: background 0.3s ease-in-out;
}

.policy-container .nav-link:hover {
    color: white;
    background: #303030;
}

.policy-item {
    text-align: center;
}

@media screen and (max-width: 992px) {
    footer {
        margin-left: initial;
    }
    
    .footer-container {
        max-width: initial;
    }

    .frame-logo img {
        width: 150px;
    }
}

@media screen and (max-width: 576px) {
    .policy-container .nav-link{
        /* margin-top: 4vh; */
        border-right: solid #303030;
        border-bottom: none;
    }

    .policy-item:first-child .nav-link {
        border-left: solid #303030;
    }

    .frame-logo img {
        width: 100px;
    }
}

