.order-container {
    margin-top: 3vh;
}

.order-row {
    height: 100vh;
}

@media screen and (max-width: 992px) {
    .order-container {
        margin-top: 13vh;
    }
    .order-row {
        height: auto;
    }
}

