.insta-container {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 10px;
    padding: 5%;
    /* width: 90%; */
    /* border: solid #303030; */
    
  }
  
  .insta-container img {
    width: 100%;
    height: auto;
    display: block;
    backface-visibility: hidden;
    transition: all 100ms ease-out;
    object-fit: cover;
  }

  .insta-overlay {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
  }

  .insta-container video {
    width: 100%;
    height: auto;
    display: block;
    transition: all 100ms ease-out;
  }

  .insta-container video:hover {
    opacity: 0.3;
    transform: scale(1.04);
    cursor: pointer;
  }
  
  .insta-container img:hover {
    opacity: 0.3;
    transform: scale(1.04);
    cursor: pointer;
  }

  .insta-overlay:hover {
    opacity: 1;
  }