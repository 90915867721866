/* .home-wrapper {
    
} */
.sec1 {
    height: 100vh ;
}

.sec2 {
    display: flex;
    justify-content: center;
    background-color: #e6e2de;
}

.sec3 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.sec4 {
    display: flex;
    /* height: 100vh; */
}

@media screen and (max-width: 992px) {

    .sec1 {
        height: fit-content;
    }
}
