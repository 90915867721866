.menu-container {
    margin-top: 2vh;
    margin-bottom: 5vh;
}

.menu-box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: auto;
}

.menu-slider-wrapper {

    display: flex;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    /* justify-content: center; */
    overflow-x: auto;
    /* padding-top: 20px; */
    /* border: solid; */
    height: 100vh;
    margin-top: 2vh;
}

.menu-column {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center ;
    /* display: flex; */
    /* justify-content: center; */
    
}

.menu-column img {
    max-width: 100%;
    height: 90%;
    cursor: pointer;
}

.menu-carousel-container {
    display: flex;
    justify-content: center;
    /* height: 100vh; */
}

.menu-carousel {
    margin-top: 5vh;
}

.menu-carousel img {
    max-width: 100%;
    max-height: 100vh;
}

@media screen and (max-width: 992px) {
    .menu-container {
        margin-top: 13vh
    }
}

@media screen and (max-width: 576px) {
    .menu-container {
        margin-top: 88px;
    }
    .menu-wrapper img {
        max-width: 100%;
    }

    .menu-slider-wrapper {
        height: initial;
    }
}