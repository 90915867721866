header {
    overflow: hidden;
    position: absolute;
    z-index: 100;
}

.bg-dark {
    background-color: #e6e2de !important;
}
.navbar-toggler {
    border: solid #303030;
    color: #303030 !important;
}

.navbar-toggler:focus {
    box-shadow: 3px 3px 3px #303030 !important;
}
/* .navbar-toggler-icon {
    color: #303030 !important;
} */

.navbar-main {
    height: 100vh;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    z-index: 100;
    position: fixed;
    border-right: solid  #303030;
    left: -210px;
}

.navbar-pages {
    display: flex;
    flex-direction: column !important;
    justify-content: space-around;
    margin-top: 6vh;
    margin-right: 200px;
}

.navbar-pages .nav-link {
    color: #303030;
    margin-top: 10px;
    font-weight: 500;
    font-size: 20px;
}

.nav-cart-login {
    position: absolute;
    bottom: 5vh;
}

.nav-cart-login .nav-link {
    margin: 10px;
    width: fit-content;
    color: #303030;
    font-weight: 500;

}
.nav-cart-login .nav-item {
    width: fit-content;
}

.ncl-1 {
    display: flex;
    flex-direction: row;
    margin-bottom: 1vh;
    justify-content: space-between;
    margin-right: 25px;
}

.nav-container {
    flex-direction: column;
}

.frame-button {
    background-color: transparent;
    border: none;
    font-family: 'Arial', sans-serif;
    font-size: 40px;
    color: #303030;
    font-weight: 700;
    margin-top: 60px;
}

.logo-container{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 5vh;
    transform: translateX(0%);
    transition: transform 1s;
    width: fit-content;
    margin-left: auto;
}

.logo-container img {
    width: 75px;
}

.logo {
    margin-left: 20px;
}

.pages {
    /* margin-left: 200px; */
    font-size: 35px;
    color: #303030;
    font-weight: 600;
    margin-left: -5%;
    margin-top: 10vh;
    transform: translateX(0);
    transition: transform 1s;
    
}

.dropdown-item {
    color: #303030 !important;
    font-weight: 400;
}

.dropdown-toggle::after {
    display: none;
}

.dropdown-item-title {
    font-size: 12px !important;
}

.dropdown-item-title-shop {
    font-size: 12px !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    margin-top: 10px;
    font-weight: 500;
    padding-left: 25px;
    color: black !important;
}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 992px) {

    .dropdown-item-title-shop {
        padding-left: 19px;
    }
    .navbar-main {
        height: auto;
        width: 100%;
        display: flex;
        border-right: none;
        border-bottom: solid #303030;
        position: fixed;
        transition: transform !important;
        transform: translateX(0) !important;
        left: initial;
        top: 0;
    }
    .nav-container {
        flex-direction: row;
    }
    .navbar-pages {
        margin-top: auto;
        transform: initial !important;
        transition: transform !important;
        margin-right: initial;
    }
    .nav-cart-login {
        flex-direction: row;
        position: initial;
        bottom: initial;
        margin-top: 15px;
        justify-content: space-between;
        transform: initial !important;
        transition: transform !important;
    }
    .ncl-1 {
        margin-bottom: auto;
    }
    .frame-button {
        transform: rotate(0deg) !important;
        transition: transform !important;
        margin-top: initial;
        pointer-events: none;
    }
    .logo-container {
        margin-left: initial;
        flex-direction: row;
        margin-top: initial;
        transition: initial !important;
        transform: initial !important;
    }
    .collapsed-content {
        margin-top: 2vh;
        margin-left: 5%;
    }
}

@media screen and (max-width: 576px) {

    .logo-container {
    }
    
    .logo-container button {
        display: none;
    }
}

