
.text tspan {
    font-weight: 800;
}

@media screen and (max-width: 576px) {

    .text {
        font-size: 24px;
    }

    .text tspan {
        text-decoration: underline;
        font-weight: 800;
    }
}