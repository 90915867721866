/*------------------5 Row Animation----------*/
.phrase-container {
    transition: transform cubic-bezier(0.07, 0.19, 0.05, 0.99) 1s;
}

.text-animation-wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-x: hidden;
}

.phrase-container {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    z-index: -10;
}

.phrase {
    font-family: Arimo;
    color: #303030;
    font-size: 6.4vw;
    font-weight: 800;
}

.phrase.highlight {
    color: #F25000 !important;
    border-bottom: solid #303030;
}


@media screen and (max-width: 992px) {
    
}

@media screen and (max-width: 768px) {
    
}

@media screen and (max-width: 576px) {
    .text-animation-wrapper {
        margin-top: 2vh;
    }
    .phrase {
        font-size: 18vw;
    }
}













/* .text-animation {

    height: 100vh;
    width: 100%; 
     margin: 0; 

     display: flex;
    flex-direction: column; 
   }   */


/* .title-animation svg {
    width: 100%;
    height: 100vh;
} */

/* .text-perimeter {
    
}


.svgwave {
    width: 100%;
    height: 100vh;
    position: relative;

  }

.svg-text {
font-size: 1rem;
font-weight: 800;
font-family: Arimo;
color: #303030;
}

.coffee-image {
    position: absolute;
    inset: 0;
    margin: auto;
    width: 50%;
    z-index: 2;
}

.coffee-image img {
    position: absolute;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%); */




/* .phrase h1{
    font-size: 10vw;
    font-weight: 800;
    line-height: 0.9em;
    margin-top: 0px;
    margin-bottom: 0px;
} */
/* 
.phrase a {
    color: #F25000;
    text-decoration: none;
} */


/* 
@media screen and (max-width: 992px) {

    .coffee-image {
        top: 62vh;
    }

    .coffee-image img {
        top: 0;
        top: 62vh;
        height: 400px;
        width: 375px;
    }
    .title-animation {
        height: 90vh;
    }
    .phrase {
        font-size: 4rem;
    }
    .middle-half {
        top: 55%;
        width: 50%;
    }
}

@media screen and (max-width: 768px) {
    .middle-half {
        width: 60%;
    }
} */



/* /* .bean-container {
    display: flex;
    justify-content: center;
    /* position: fixed; */
    /* transform:  translatex(-50%) translatey(-50%); 
} 

.bean-container img {
    width: 30%;
    height: auto;
    margin-top: 20%;
    position: fixed;
} */

/* .first-half {
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.middle-half {
    display: flex;
    justify-content: center; 
    margin-left: 20%;
    margin-right: 20%;
    position: absolute;
    margin: auto;
    width: 35%;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.second-half {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    margin-bottom: 4vh;
    z-index: 3;
} */


/*-------------------------Cross Section Animation--------------*/

/* .text-animation-wrapper {
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.text-animation-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 10;
}

.phrase {
    font-family: Arimo;
    color: #303030;
    font-size: 6vw;
    font-weight: 800;
}

.firstHalf {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;

}

.secondHalf {
    display: flex;
    flex-direction: column;
}

.between-phrase1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.image-container {
    display: flex;
    margin: auto;
    z-index: 1;
    margin: 100px 25vh 100px 25vh;

}
.image-container img {
    max-width: 100%;
    width: auto;
    height: auto;
}

.between-phrase2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.thirdHalf {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

@media screen and (max-width: 992px) {

    .text-animation-wrapper {
        height: fit-content;
    }

    .image-container {
        margin: 80px 150px 80px 150px;
    }

}

@media screen and (max-width: 768px) {
    .image-container {
        margin: 70px 70px 70px 70px;
    }
} */


