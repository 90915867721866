

.artShow-container {
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
}

.coming-soon-container {
    display: flex;
    justify-content: center;
    height: 100vh;
}

.coming-soon-container {
    align-items: center;
}

.artist-wrapper {
    /* height: 100vh; */
    justify-content: center;
    align-items: center;
}

/*-----ARTIST CAROUSEL----*/

.carousel-container {
    height: 100vh;
}

.artShow-carousel {
    /* margin: 2vh; */
    height: 100%;
    width: 100%;
}

.carousel-inner {
    height: 100%;
}

.carousel-item {
    height: 100%;
}

.artShow-carousel .lazy-load-image-background {
    height: 100%;
}

.artist-gallery-container .lazy-load-image-background {
    height: 100%;
}

.artshow-carousel-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*-----ARTIST DESCRIPTION----*/
.artist-container {
    margin: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.artist-desc span{
    font-size: 16px;
    font-style: italic;
    color: gray;
}

.coming-soon h1 {
    font-size: 12vw;
    font-weight: 700;
    color: #F25000;
    text-decoration-line:underline;
}

.hr-container {
    display: flex;
    flex-direction: column;
}

.top-hr {
    display: flex;
    justify-content: space-evenly;
}
.mid-hr {
    display: flex;
    justify-content: space-evenly;
}
.btm-hr {
    display: flex;
    justify-content: space-evenly;
}
.hr-phrase {
    font-size: 6vw;
    font-weight: 700;
}

:root {
    --scroll: 0;
}


.mid-hr {
    color: #F25000;
    animation: moveX 1s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll));
    
}

.artist-social a{
    text-decoration: none;
    color: #F25000 !important;
    font-weight: 600;
}

.artist-social a:hover{
    cursor: pointer;
    opacity: 0.5;
}

@keyframes moveX {
    to {
        transform: translateX(20px);
    }
}

@media screen and (max-width: 992px) {
    .artShow-container {
        margin-top: 13vh;
    }
}

@media screen and (max-width: 576px){
    .artShow-container {
        margin-top: 88px;
    }

    .artShow-container .page-title {
        text-align: center;
    }

    .artShow-container .sub-title {
        text-align: center;
    }
}
