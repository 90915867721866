

.comingsoon-container {
    justify-content: center;
    /* align-items: center; */
    display: flex;
    height: 100%;
}

.comingsoon-wrapper {
    align-items: center;
    justify-content: center;
    width: 100%;
}

.commingsoon-phrase {
    justify-content: center;
    display: flex;
    font-size: 4em;
    font-weight: 800;
    color: #F25000;
    flex-direction: column;

}

.commingsoon-phrase-shop{
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: 4em;
    font-weight: 800;
}

.comingsoon-equal {
    font-size: 4rem;
    width: fit-content;
    display: flex;
    justify-content: center;
}

.Typewriter__cursor {
    display: none;
}

@media screen and (max-width: 768px) {
    .comingsoon-container {
        margin-top: 88px;
    }
}