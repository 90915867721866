
.about-container {
    margin-top: 2vh;
    margin-bottom: 5vh;
    /* overflow-x: hidden; */
}
.page-title {
    border-bottom: solid #303030;
}

@keyframes animate-fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.page-title h1 {
    font-size: 6rem;
    font-weight: 800;
}

.sub-title {
    border-bottom: solid #303030;
}

.sub-title h2 {
    margin-left: 10px;
    margin-bottom: 0;
}

.image-row {
    border-bottom: solid #303030;
}

.image-row h1 {
    font-size: clamp(2rem, 4vw, 4rem);
    font-weight: 700;
    line-height: 1.3em;
    /* margin: 0 auto; */
}

.img-container {
    display: flex;
    justify-content: center;
}

.img-container .lazy-load-image-background {
    max-width: 90%;
    object-fit: contain;

}

.txt-container {
    display: flex;
    justify-content: center;
}

.selectWord {
    color: #F25000;
}


@media screen and (max-width: 992px) {
    .about-container {
        margin-top: 13vh;
    }
    .page-title h1{
        font-size: 4rem;
    }
}

@media screen and (max-width: 576px) {
    .about-container {
        margin-top: 88px;
    }
    .txt-container {
        padding: 5vh 11vw 0 11vw;
        text-align: center;           
    }
}