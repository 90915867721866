

.app-container {
   /* margin: auto; */
   position: relative;
   /* align-items: center; */
   display: flex;
   flex-direction: column;
   height: 100vh;
   /* padding-left: 0 !important;
   padding-right: 0 !important; */
}

.nav-blank {
   width: 220px;
}

@media (min-width: 1400px) {
   .app-container {
      max-width: none;
   }
}


@media screen and (max-width: 992px) {
   .app-container {
    position: initial;
    left: initial;
    max-width: none !important;
   }

   .nav-blank {
      display: none;
   }
}

@media screen and (max-width: 768px) {
   .app-container {
      max-width: none !important;
   }
}

@media screen and (max-width: 576px) {
   .app-container {
      /* margin-top: 88px; */
   }
}